import { globalConfig, retryAxiosInstance } from '@/types/retry'
import { formatFreightBillDate } from './formatDate'

export const openInNewTab = (url: string) => {
  window.open(url, '_blank')
}

export const timeOfDay = () => {
  const hour = new Date().getHours()
  if (hour < 12) {
    return 'Morning'
  } else if (hour < 18) {
    return 'Afternoon'
  }
  return 'Evening'
}

export const testForCharacters = (value: string) => {
  const regex = /^[^\s]+(\s+[^\s]+)*$/
  return !regex.test(value)
}

export const formatPhoneNumber = (value: string) => {
  const phoneNumber = value.replace(/[^0-9]/g, '') // Remove all non-numeric characters
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength < 4) {
    return phoneNumber
  } else if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`
  } else {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`
  }
}

export function formatPhoneNumberForCognito(phoneNumber: string) {
  const digitsOnly = phoneNumber.replace(/\D/g, '')

  const formattedNumber = `+1${digitsOnly}`

  return formattedNumber
}

export const nptRep = {
  name: 'Zaraha Barry',
  email: 'zbarry@nopk.biz',
  phone: '777-777-7777',
  image: 'https://via.placeholder.com/150',
}

export const mapFreightCharges = (freightCharges: string | undefined) => {
  if (freightCharges === 'P') return 'Prepaid'
  if (freightCharges === 'C') return 'Collect'
  else return 'N/A'
}

export const listWithCommas = (
  strings: (string | undefined)[]
): string | null => {
  const validStrings = strings.filter(
    (s) => typeof s === 'string' && s.length > 0
  ) as string[]

  if (validStrings.length === 1) {
    return validStrings[0]
  } else if (validStrings.length === 0) {
    return null
  } else {
    return `${validStrings.slice().sort().join(', ')}`
  }
}

export const sortObjectsByString = (
  objects: any[],
  identifier: string
): any[] => {
  return objects
    .slice()
    .sort((a: any, b: any) =>
      a[identifier]
        .replace(/\s+/g, '')
        .localeCompare(b[identifier].replace(/\s+/g, ''))
    )
}

export const validProNumber = async (proNumber: string) => {
  const mustBeInteger = /^[0-9]*$/

  if (proNumber.length === 0 || !mustBeInteger.test(proNumber)) return false
  try {
    const response = await retryAxiosInstance.post(
      `/api/track-shipment`,
      `pronumber=${proNumber}&protype=${proNumber.length == 8 ? 'N' : 'I'}`,
      {
        ...globalConfig,
        timeout: 2500, // Set a timeout of 2.5 seconds
      }
    )

    const json = response.data
    if (json.errors === 'N') {
      return true
    } else {
      console.log(`🚨 [API] POST /api/track-shipment | Failed with Error:`)
      return false
    }
  } catch (error) {
    console.log(`🚨 [API] POST /api/track-shipment | Failed with Error:`)
    console.log(error)
    // If the request fails, return true to allow the user to continue with the shipment
    return true
  }
}

export const getPaymentTypeValue = (paymentType?: string) => {
  if (!paymentType) return 'N/A'
  if (paymentType === 'P') return 'Prepaid'
  if (paymentType === 'C') return 'Collect'
  else return paymentType
}

export function formatUTCDate(date: Date): string {
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const year = String(date.getUTCFullYear())

  return `${month}/${day}/${year}`
}

export const formatPickupDate = (pickupDate?: string, isCartage?: boolean) => {
  if (!pickupDate) return ''
  return !isCartage
    ? pickupDate
        .replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$2/$3/$1')
        .replace('-', '/')
    : pickupDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$2/$3/$1')
}

export const formatInterlineBillDate = (interlineDate?: string | null) => {
  if (!interlineDate || interlineDate === '0') return 'n/a'
  return interlineDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$2/$3/$1') ?? 'n/a'
}
